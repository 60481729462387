<template>
    <div class="card p-2" style="min-height: calc(100vh - 185px);">
        <TitleButton
            btnTitle="Add New Group"
            title="NGOAB - Chart of accounts"
            :showBtn="false"
            :showAddNew="true"
            @onClickAddNewButton="openAddNewAccountModal"
        />
        <div class="mt-2"></div>
        <Tab @onClickTab="onClickTab" :tabs="homeContent.ngo_account_type" routeName="chart-of-accounts-ngoab" />
        <hr>
        <TreeBrowser
            :style="{opacity: chartNgoLoading ? .5 : 1}"
            v-for="(item, i) in chartAccounts"
            :key="i"
            :node="item" 
        />
        <p v-if="!chartAccounts.length" class="text-center mt-5">No data</p>
        <ChartCreateModal
            :isGroup="isGroup"
            :parentGroup="parentGroup"
            :accountType="homeContent.ngo_account_type"
            :accountSlug="homeContent.account_slug"
            :eventName="eventName"
            :title="title"
            :loading="chartNgoCreateLoading"
            :formData="createData"
            ref="createChartAccount"
            @onSubmit="createChartAccount"
            @onHeadAdd="createHead"
        />
        <ChartEditModal
            :eventName="editEventName"
            :title="editTitle"
            :loading="chartNgoCreateLoading"
            :accountSlug="homeContent.account_slug"
            :formData="editData"
            :groups="groupsWithoutEditItem"
            ref="editChartAccount"
            @onUpdateHead="onUpdateHead"
            @onUpdateGroup="onUpdateGroup"
        />
    </div>
</template>

<script>
import handleChartAccount from '@/services/modules/chartAccount'
import { inject } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import {mapMutations} from 'vuex'

import TreeBrowser from '@/components/atom/TreeBrowser'
import Tab from '@/components/atom/Tab'
import TitleButton from '@/components/atom/TitleButton'
import ChartCreateModal from '@/components/molecule/company/chart-of-account-ngoab/CreateModal'
import ChartEditModal from '@/components/molecule/company/chart-of-account-ngoab/EditModal'

export default {
    name: 'ChartAccountNGOABList',

    components: {
        TitleButton,
        TreeBrowser,
        Tab,
        ChartCreateModal,
        ChartEditModal
    },

    data: () => ({
        isGroup: false,
        parentGroup: '',
        homeContent: {},
        title: 'Add new account',
        editTitle: 'Add new account',
        eventName: '',
        editEventName: 'onSubmit',
        groups: [],
        groupsWithoutEditItem: [],
        currentEditItemId: null,
        createData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            account_slug: null,
            company_id: ''
        },
        editData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            account_slug: null,
            company_id: '',
            _method: 'PATCH',
        }
    }),

    methods: {
        sortChart(data) {
            data.map(i => {
                i.child.sort((a, b) => {
                    if(a.type == 'group' && b.type == 'endpoint') return 1
                    if(a.type == 'endpoint' && b.type == 'group') return -1
                    if(a.type == b.type) return 0
                })
                this.sortChart(i.child)
            })
        },
        ...mapMutations({
            setChartAccount: 'chartAccount/setChartAccount'
        }),

        onClickAddNewButton() {
        },

        openAddNewAccountModal () {
            this.isGroup = false
            this.eventName = 'onSubmit'
            this.title = 'Add new group'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = null
            this.$refs.createChartAccount.toggleModal();
        },

        onClickTab() {
            this.getChartAccount(this.$route.params.accountType)
        },

        setGroupArr(data) {
            data.map(item => {
                if(item.type == 'group') {
                    this.groups.push({id: item.id, name: item.name})
                }
                this.setGroupArr(item.child)
            })
        },

        async getChartAccount(type) {
            try {
                this.chartNgoLoading = true
                let res = await this.fetchChartAccountNGOAB({companyId: this.$route.params.companyId, type:type})
                if(!res.status) {
                    //this.showError(res.message)
                    this.setChartAccount({})
                }
                if(res.status) {
                    this.setChartAccount(res.data)
                    this.sortChart(this.chartAccounts)
                    this.groups = []

                    this.setGroupArr(res.data)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoLoading = false
            }
        },

        async createChartAccount() {
            try {
                this.chartNgoCreateLoading = true
                let res = await this.createNewAccountNgo(this.createData)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.getChartAccount(this.$route.params.accountType)
                    this.createData.name = '',
                    this.createData.status = 'active'
                    this.createData.account_head_id = null
                    this.$refs.createChartAccount.toggleModal();
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoCreateLoading = false
            }
        },

        async onUpdateGroup() {
            // return;
            try {
                this.chartNgoCreateLoading = true
                let res = await this.updateAccountGroupNgo({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount(this.$route.params.accountType)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoCreateLoading = false
            }
        },

        async createHead() {
            try {
                this.chartNgoCreateLoading = true
                let res = await this.createNewAccountHeadNgo(this.createData)
                if(!res.status) { 
                    this.showError(res.message)
                }
                if(res.status) {
                    this.getChartAccount(this.$route.params.accountType)
                    this.createData.name = '',
                    this.createData.status = 'active'
                    this.createData.account_head_id = null
                    this.$refs.createChartAccount.toggleModal();
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoCreateLoading = false
            }
        },

        async onUpdateHead() {
            // return
            try {
                this.chartNgoCreateLoading = true
                let res = await this.updateAccountHeadNgo({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if(!res.status) { 
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount(this.$route.params.accountType)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoCreateLoading = false
            }
        },

        async getHomeContent(type) {
            try {
                this.chartNgoLoading = true
                let res = await this.fetchHome()
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.homeContent = res.data
                    this.homeContent.ngo_account_type = {"expense": "Expense"}
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartNgoLoading = false
            }
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()  

        const {
            fetchChartAccountNGOAB,
            fetchHome,
            createNewAccountNgo,
            createNewAccountHeadNgo,
            chartNgoCreateLoading,
            updateAccountGroupNgo,
            updateAccountHeadNgo,
            chartNgoLoading
        } = handleChartAccount()

        return {
            chartAccounts: computed(() => store.state.chartAccount.chartAccounts),
            chartNgoCreateLoading,
            fetchChartAccountNGOAB,
            createNewAccountNgo,
            createNewAccountHeadNgo,
            updateAccountGroupNgo,
            updateAccountHeadNgo,
            chartNgoLoading,
            fetchHome,
            showError,
            showSuccess
        }
    },

    mounted () {
        const createToggleModal = this.$refs.createChartAccount
        const editToggleModal = this.$refs.editChartAccount

        this.getChartAccount(this.$route.params.accountType)
        this.getHomeContent() //status, voucher, account_type

        this.emitter.on('onClickAddGroup', (evt) => {
            this.isGroup = true
            this.title = 'Add new sub group'
            this.eventName = 'onSubmit'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = evt.id
            this.parentGroup = evt.name
            createToggleModal.toggleModal();
        })

        this.emitter.on('onClickAddHead', (evt) => {
            this.isGroup = true
            this.eventName = 'onHeadAdd'
            this.title = 'Add new account head'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = evt.id
            this.parentGroup = evt.name
            createToggleModal.toggleModal();
        })

        this.emitter.on('onChartUpdate', (evt) => {
            this.editTitle = evt.title
            this.currentEditItemId = evt.data.id
            if(evt.eventName == 'onUpdateHead') {
                this.groupsWithoutEditItem = this.groups
            }
            if(evt.eventName == 'onUpdateGroup') {
                this.groupsWithoutEditItem = this.groups.filter(group => group.id !== evt.data.id)
                this.groupsWithoutEditItem.unshift({id: null, name: 'Set as parent'})
            }
            this.editEventName = evt.eventName
            this.editData.account_head_id = evt.data.account_head_id
            this.editData.name = evt.data.name
            this.editData.account_slug = evt.data.account_slug
            this.editData.company_id = this.$route.params.companyId
            this.editData.account_type = this.$route.params.accountType
            editToggleModal.toggleModal();
        })
    }
}
</script>
